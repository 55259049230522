.Main {
    text-align: center;
    background-color: rgb(206, 213, 223);
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }
  
  .Card {
    background-color: white;
    padding: 32px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
  }
  