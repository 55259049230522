// Colors
$green  : #3aad91;
$blue   : #2989c6;
$grey   : #414141;
$orange : #fb6733;
$red    : #d33847;
$grey   : #4c5e65;

// Helpers
@mixin clear-fix {
  &:before,
  &:after {
    content: "";
    display: table;
  }
  &:after {
    clear: both;
  }
  .lte7 & {
    zoom: 1;
  }
}

// Base Layout Styles
/* 
html, body {
  font-family: "Open Sans", Helvetica, arial;
  font-size: 16px;
  line-height: 1.36;
  max-width: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;

  * {
    box-sizing: border-box;
  }
}

.container {
  margin: 20px auto;
  max-width: 400px;
  width: 100%;
} */

// Buttons

.btn {
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  /* padding: 10px 12px; */
  text-align: center;

  &.-default {
    background-color: #fff;
    border: 1px solid #ddd;
    color: $grey;

    &:hover {
      background-color: rgb(66, 61, 61);
    }
  }

  &.-primary {
    background-color: $blue;
    border: 1px solid $blue;

    &:hover {
      background-color: darken($blue, 5%);
    }
  }
}

// Progress
.progress {
  margin-bottom: 20px;
  transition: all 0.2s ease;
  //background-color: lightblue
  background: linear-gradient(to right, #fefb72, #f0bb31)
}

.progress-step {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 18px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

.docs-terms a {
  color: blue !important;
}
// Alignments

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

// Forms

.form-fields {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    margin-bottom: 20px;
  }
}

.form-footer {
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding-top: 20px;
}

.label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;
}

label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px;

  .checkbox &,
  .radio & {
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    padding: 1px 12px 1px 30px;
    position: relative;

    input {
      left: 0;
      position: absolute;
      top: 0;
    }
  }
}

input {
  font-size: 16px;
  padding: 8px;
  width: 100%;
}

select {
  font-size: 16px;
  padding: 8px;
}

.btn.-default:hover {
  background-color: #ddd;
}


.error {
  /* border: 1px solid red; */
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.error-message {
  display: none;
}

.error-message__visible {
  display: block;
  font-size: 0.75rem;
  margin: 0;
  color: #a94442;
}


.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.formErrors{
  color: #a94442;
}

.terms{
  max-height: 300px;
    overflow: auto;
    border: 1px dashed blue;
    padding: 11px;
    text-align: justify;
}