@import url(https://fonts.googleapis.com/css?family=Roboto:400,700|Material+Icons);

@font-face {
  font-family: 'FuturaBold';
  src: url('/fonts/Futura Bold/FuturaBold.eot'); /* IE9 Compat Modes */
  src: local('Futura Bold'), local('Futura-Bold'),
       url('/fonts/Futura Bold/FuturaBold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('/fonts/Futura Bold/FuturaBold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/fonts/Futura Bold/Futura-Bold.woff') format('woff'), /* Modern Browsers */
       url('/fonts/Futura Bold/Futura-Bold.ttf') format('truetype'), /* Safari, Android, iOS */
       url('/fonts/Futura Bold/Futura-Bold.svg#OpenSans') format('svg'), /* Legacy iOS */
       url("/fonts/Futura Bold/Futura Bold.otf") format("opentype");
}
@font-face {
  font-family: 'DK_Drop_Dead_Gorgeous';
  src: url('/fonts/DK_Drop_Dead_Gorgeous.otf'); /* IE9 Compat Modes */
  src: local('DK_Drop_Dead_Gorgeous'), local('DK_Drop_Dead_Gorgeous'),
  url("/fonts/DK_Drop_Dead_Gorgeous.otf") format("opentype");
}

@font-face {
  font-family: 'OpenSans';
  src: url('/fonts/Open_sans/OpenSans-Regular.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans'), local('OpenSans'),
  url("/fonts/Open_sans/OpenSans-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSansBold';
  src: url('/fonts/Open_sans/OpenSans-Bold.ttf'); /* IE9 Compat Modes */
  src: local('OpenSansBold'), local('OpenSansBold'),
  url("/fonts/Open_sans/OpenSans-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSansBold2';
  src: url('/fonts/Open_sans/OpenSans-Bold 2.ttf'); /* IE9 Compat Modes */
  src: local('OpenSansBold2'), local('OpenSansBold2'),
  url("/fonts/Open_sans/OpenSans-Bold 2.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSans-BoldItalic2';
  src: url('/fonts/Open_sans/OpenSans-BoldItalic 2.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans-BoldItalic2'), local('OpenSans-BoldItalic2'),
  url("/fonts/Open_sans/OpenSans-BoldItalic 2.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSans-BoldItalic';
  src: url('/fonts/Open_sans/OpenSans-BoldItalic.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans-BoldItalic'), local('OpenSans-BoldItalic'),
  url("/fonts/Open_sans/OpenSans-BoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSans-ExtraBold';
  src: url('/fonts/Open_sans/OpenSans-ExtraBold.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans-ExtraBold'), local('OpenSans-ExtraBold'),
  url("/fonts/Open_sans/OpenSans-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSans-ExtraBold2';
  src: url('/fonts/Open_sans/OpenSans-ExtraBold 2.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans-ExtraBold2'), local('OpenSans-ExtraBold2'),
  url("/fonts/Open_sans/OpenSans-ExtraBold.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSans-ExtraBoldItalic';
  src: url('/fonts/Open_sans/OpenSans-ExtraBoldItalic.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans-ExtraBoldItalic'), local('OpenSans-ExtraBoldItalic'),
  url("/fonts/Open_sans/OpenSans-ExtraBoldItalic.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSans-Italic';
  src: url('/fonts/Open_sans/OpenSans-Italic.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans-Italic'), local('OpenSans-Italic'),
  url("/fonts/Open_sans/OpenSans-Italic.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSans-Light';
  src: url('/fonts/Open_sans/OpenSans-Light.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans-Light'), local('OpenSans-Light'),
  url("/fonts/Open_sans/OpenSans-Light.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSans-LightItalic';
  src: url('/fonts/Open_sans/OpenSans-LightItalic.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans-LightItalic'), local('OpenSans-LightItalic'),
  url("/fonts/Open_sans/OpenSans-LightItalic.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSans-Semibold';
  src: url('/fonts/Open_sans/OpenSans-Semibold.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans-Semibold'), local('OpenSans-Semibold'),
  url("/fonts/Open_sans/OpenSans-Semibold.ttf") format("truetype");
}
@font-face {
  font-family: 'OpenSans-SemiboldItalic';
  src: url('/fonts/Open_sans/OpenSans-SemiboldItalic.ttf'); /* IE9 Compat Modes */
  src: local('OpenSans-SemiboldItalic'), local('OpenSans-SemiboldItalic'),
  url("/fonts/Open_sans/OpenSans-SemiboldItalic.ttf") format("truetype");
}
*{
  font-family: 'OpenSans', 'OpenSansBold', 'OpenSansBold2', 
      'OpenSans-BoldItalic', 'OpenSans-BoldItalic2', 
      'OpenSans-ExtraBold', 'OpenSans-ExtraBold2',
      'OpenSans-ExtraBoldItalic', 'OpenSans-Italic', 'OpenSans-Italic2',
      'OpenSans-Light', 'OpenSans-LightItalic',
      'OpenSans-Semibold', 'OpenSans-SemiboldItalic',
      'FuturaBold', 'Futura-Bold', 'DK_Drop_Dead_Gorgeous'/* , 
      'Titillium Web' */;
}


body {
  /* background-color: #DDEDF4 !important; */
  margin: 0;
  font-family: 'OpenSans', 'OpenSansBold', 'OpenSansBold2', 
      'OpenSans-BoldItalic', 'OpenSans-BoldItalic2', 
      'OpenSans-ExtraBold', 'OpenSans-ExtraBold2',
      'OpenSans-ExtraBoldItalic', 'OpenSans-Italic', 'OpenSans-Italic2',
      'OpenSans-Light', 'OpenSans-LightItalic',
      'OpenSans-Semibold', 'OpenSans-SemiboldItalic',
      'FuturaBold', 'Futura-Bold', 'DK_Drop_Dead_Gorgeous'/* , 
      'Titillium Web' */;
  /* font-family:'FuturaBold', 'Futura-Bold','Titillium Web',-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.kep-login-facebook.metro{
  border-radius: 200px !important; 
  padding: 5px !important;

}


h1{
  font-size: 24px;
  text-transform: uppercase;
  font-family: 'FuturaBold';
}
h2{
  font-size: 18px;
  text-transform: uppercase;
  font-family: 'FuturaBold';
}
h3{
  text-transform: uppercase;
  font-size: 16px;
}
h4{
	text-transform: uppercase;
  font-size: 13px;
}
h5{
	text-transform: uppercase;
  font-size: 12px;
}
h6{
	text-transform: uppercase;
  font-size: 11px;
}


.input-range__label--max .input-range__label-container {
  left: 20% !important;
  color: #EFEFEF !important;
}

.input-range__label-container {
  left: -10% !important;
  position: relative;
  color: #009789 !important;
}

.input-range__label--min .input-range__label-container {
  color: #EFEFEF !important;
}

.input-range__label--value .input-range__label-container {
  color: #009789 !important;
}

.input-range__slider {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 0% !important;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  -webkit-transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  width: 1rem;
}

.input-range__track .input-range__track--background .input-range__track--active {
  background: #8d8d8d !important;
}
.input-range__track {
  background: #EFEFEF !important;
}
.input-range .input-range__track .input-range__track--background {
  background: #EFEFEF !important;
}

.input-range__track .input-range__track--active {
  background: #8d8d8d !important;
}
.input-range__track .input-range__track--background {
  background: #EFEFEF !important;
}

/* .btn-normal {
  border-radius: 20px !important;
  border: 1px solid !important;
  cursor: pointer;
  font-size: 16px;
  padding: 10px 12px;
  text-align: center;
  color: #3350B9 !important;
  background-color: linear-gradient(to right, #3350B9 0%, #009789 100%) !important;
  border-color: linear-gradient(to right, #3350B9 0%, #009789 100%) !important; 
} */

.btn-normal{
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #00a950), to(#3350B9));
	background-image: linear-gradient(to bottom, #00a950 10%, #3350B9 100%);
	border-radius: 40px;
  box-sizing: border-box;
	color: #3350B9;
	display: block;
	font: 1.125rem 'Oswald', Arial, sans-serif; /*18*/
	height: 40px;
	letter-spacing: 1px;
	margin: 0 auto;
	padding: 4px;
	position: relative;
  text-decoration: none;
	text-transform: uppercase;
	/* width: 264px; */
	/* z-index: 2; */
}

.btn-normal:hover {
	color: #fff;
}

.btn-normal span {
	align-items: center;
	background: #fff;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	height: 100%;
	-webkit-transition: background .90s ease;
	transition: background .90s ease;
  width: 100%;
  padding: 10px;
}

.btn-normal:hover span {
	background: transparent;
}

hr{
  background: -webkit-gradient(linear, left top, right top, color-stop(10%, #00a950), to(#3350B9));
  background: linear-gradient(to right, #00a950 10%, #3350B9 100%);
	padding: 1px;
}

.nav-link:hover {
  background-color: #EFEFEF !important;
  color: #000 !important;
}
.active {
  background-color: #EFEFEF !important;
  color: #000 !important;
}

/* .nav-link{
  padding-left: 20% !important;
} */


button{
	background-image: -webkit-gradient(linear, left top, left bottom, color-stop(10%, #00a950), to(#3350B9));
	background-image: linear-gradient(to bottom, #00a950 10%, #3350B9 100%);
	border-radius: 40px;
  box-sizing: border-box;
	color: #3350B9;
	display: inline-block;
	font: 1.125rem 'Oswald', Arial, sans-serif; /*18*/
	height: 40px;
	letter-spacing: 1px;
	margin: 0 auto;
	padding: 4px;
	position: relative;
  text-decoration: none;
	text-transform: uppercase;
	/* width: 264px; */
	/* z-index: 2; */
}

button:hover {
	color: #fff;
}

button span {
	align-items: center;
	background: #fff;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	height: 100%;
	-webkit-transition: background .90s ease;
	transition: background .90s ease;
	width: 100%;
}

button:hover span {
	background: transparent;
}

video{
  width: 100% !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.row{
  margin-right: 0px !important;
  margin-left: 0px !important;
}

/* #212221#1181B2#DDEDF4#44449B */

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

/* media styles */
@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@media screen and (max-width: 767px){
.recharts-surface{
  width:399px !important;
}
}


@media screen and (max-width: 540px){
 .recharts-surface{
   width:350px !important;
 }
 }

 
@media screen and (max-width: 500px){
  .recharts-surface{
    width:250px !important;
  }
  }

  
@media screen and (max-width: 402px){
  .recharts-surface{
    width:200px !important;
  }
  }

  .recharts-legend-item{
    width:350px;
  }
/* end media styles */

button:disabled {
  background: transparent !important;
  cursor: default;
}


.signupForm {
	max-width: 500px;
    margin: auto;
    padding: 20px 20px 0px 20px;
}

.has-error .form-control {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.formErrors{
    color: #a94442;
}

.my-facebook-button-class{
    font-size: 10px;
    background-color: #a94442
}


.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    color: #495057 !important;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff;
}

.signupForm a {
    color: #007bff !important;
    text-decoration: none;
    background-color: transparent;
}

.signupForm a:hover {
    color: #007bff !important;
    text-decoration: none;
    background-color: transparent;
}




.bgBar {
    background-color: #009789 !important;
    padding: 10px 14px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    width: 100%;
}

.reset-box{
    padding-top: 48px;
    padding-bottom: 48px;
    max-width: 600px;
    margin: 0 auto;
}

a{
    color: #8d8d8d !important;
  }
  
  /* a:hover{
    color: #FFF !important;
  }
  a:active{
    color: red !important;
  }
   */
  .userName{
    position: absolute !important; 
    /* top:121px !important; 
    left:60px !important; */
    color: #8d8d8d !important;
  }

 /*  .navbar-nav{
    text-align: left;
  } */

.ReactModal__Overlay--after-open{
  z-index: 9999 !important;
/*   background-image: linear-gradient(to bottom, #00a950 10%, #3350B9 100%) !important;
 */  background-image: -webkit-gradient(linear, left top, left bottom, from(#3350B9), color-stop(50%, #00a950), color-stop(50%, rgba(255, 255, 255, 0.75)), color-stop(50%, transparent)) !important;  background-image: linear-gradient(to bottom, #3350B9 0%, #00a950 50%, rgba(255, 255, 255, 0.75) 50%, transparent 50%) !important
}

.ReactModal__Overlay {
  opacity: 0;
  -webkit-transform: translateY(-500px);
          transform: translateY(-500px);
  -webkit-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  -webkit-transform: translateX(0px);
          transform: translateX(0px);
}

.footerBG{
    background-image:url('/images/footer.jpg');
    background-repeat: no-repeat;
    /* background-position:center; */
	background-size:cover;
    width: 100%;
    height: 100%;
    min-height: 186px;
    position: absolute;
}

.social-icon{
    padding:5px;
    max-width: 40px;
}


.blur {
  /* background:url('/images/Homepage/header-1.jpg') no-repeat top; */
  background:url('/images/Homepage/header-1.jpg') no-repeat top;
  background-size:cover;
  position:absolute;
  width:100%;
  min-height: 100%;
  padding-bottom: 64px;
  text-align: left;
  -webkit-animation: bg-anim 4s;
          animation: bg-anim 4s;
}


.bg-banner {
  /* background:url('/images/Homepage/header-1.jpg') no-repeat top; */
  background:url('/images/Homepage/note.jpg') no-repeat top;
  /* background-size:cover; */
  position:absolute;
  width:100%;
  min-height: 100%;
  padding-bottom: 64px;
  text-align: left;
  -webkit-animation: bg-anim 4s;
          animation: bg-anim 4s;
}

/* media styles */
@-webkit-keyframes bg-anim {
  from {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
  }
  to {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
}
@keyframes bg-anim {
  from {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
  }
  to {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
}

span {
  text-align: right;
  /* color:blue; */
  /* font-size:50px; */
  /* font-weight:bold; */
  position:relative;
  /* position:absolute; */
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(13px);
  filter: blur(0px);
}


html,
body {
   margin:0;
   padding:0;
   height:100%;
}
#container {
   min-height:100%;
   position:absolute;
   width: 100%;
}
#header {
   /* background:#1181B2; */
   /* background: linear-gradient(to right, rgba(17,129,178,1) 0%, rgba(0,177,255,1) 100%); */
   background: -webkit-gradient(linear, left top, right top, from(#EFEFEF), to(#EFEFEF));
   background: linear-gradient(to right, #EFEFEF 0%, #EFEFEF 100%);
   /* padding:10px; */
   color: #DDEDF4 !important;
}
#body {
   /* padding:10px; */
   /* background: linear-gradient(to right, #DDEDF4 0%, rgb(87,169,181) 100%); */ /* #DDEDF4; */
   padding-bottom:64px;   /* Height of the footer */
   min-height: 100vh;
   background-color: #EFEFEF;
   
}
#footer {
   position:absolute;
   bottom:0;
   width:100%;
   height:64px;   /* Height of the footer */
   /* background:#44449B; */
   /* color: antiquewhite;
   background: linear-gradient(to right, rgba(125, 38, 240, 0.877) 0%, rgba(68,68,155,1) 100%); */
}
/* 
.input-range__track {
  background: #3f51b5 !important;
}
.input-range__track--active {
  background: red !important;
}
.input-range__label-container{
  color: dodgerblue !important;
}
 */



@media screen and (max-width: 767px){
  .blur {
    background:url('/images/Homepage/header-1.jpg') no-repeat center;
    background-size:cover;
    position:absolute;
    width:100%;
    min-height:100%;
    padding-bottom: 64px;
    text-align: left;
  }

  .calculator-container{
    margin-left: 0px;
    width: 25%;
}

  }
  
  
  @media screen and (max-width: 540px){
    .blur {
      background:url('/images/Homepage/header-1.jpg') no-repeat center;
      background-size:cover;
      position:absolute;
      width:100%;
      min-height:100%;
      padding-bottom: 64px;
      text-align: left;
    }
   }
  
   
  @media screen and (max-width: 500px){
    
    .blur {
      background:url('/images/Homepage/header-1.jpg') no-repeat center;
      background-size:cover;
      position:absolute;
      width:100%;
      min-height:100%;
      padding-bottom: 64px;
      text-align: left;
    }
    }
  
    
  @media screen and (max-width: 402px){
    .blur {
      background:url('/images/Homepage/header-1.jpg') no-repeat center;
      background-size:cover;
      /* -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -o-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px); */
      position:absolute;
      width:100%;
      min-height:100%;
      padding-bottom: 64px;
      text-align: left;
    }

    .btn-normal {
      width: 100%;
      /* font-size: 3.8rem; */
    }

  }


  .calculator-container-header{
    background: -webkit-gradient(linear, left top, right top, from(#3350B9), to(#009789));
    background: linear-gradient(to right, #3350B9 0%, #009789 100%);
    padding-top: 0px;
    /* margin-left: 0px; */
    padding-bottom: 0px;
}


.calculator-container{
    /* background: linear-gradient(to right, #3350B9 0%, #009789 100%); */
    background-color: white;
    padding-top: 0px;
    /* margin-left: 0px; */
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
}
.calculator-header{
  background: -webkit-gradient(linear, left top, right top, color-stop(20%, #3350B9), to(#009789));
  background: linear-gradient(to right, #3350B9 20%, #009789 100%);
  padding-top: 15px;
  padding-bottom: 15px;
  color: white;
  text-align: left;
}

.banner-bg{
  /* min-height: 171vh; */
  width: 100%;
}

.overlapping-div{
  /* border: 1px solid red; */
  position: absolute;
  z-index: 100;
  background: transparent;
  /* width: 100%; */
  padding: 20px 32px 0px 15px;
}

.overlapping-note-div{
  position: absolute;z-index: 100;background: transparent;
  padding: 20px 32px 0px 15px;
  min-width: 115vh;
}

.wrapper {
  padding:10px;
  background:#bada55;
  border:1px solid;
  display:inline-block;
  margin:25px
}

.img-section1{
  width: 100%;
}

@media screen and (max-width: 1200px){
  .img-section1{
    width: 100%;
  }
  .overlapping-div{
    width: 36vw;
  }
  .overlapping-div h2{
    font-size: 1.5rem;
  }
}


@media screen and (max-width: 576px){
  
  .col-xs-12{
    padding-top: 20px !important;
  }
}
#logo_wrapper{
  margin:0 auto;
  overflow:hidden;
  width:1200px;
  position:relative;
  height: 310px;
}
@media screen and (max-width: 1295px){
  #logo_wrapper{
    display: none;
  }
}
/* BLINKING EYES EFFECT LOGO */
@-webkit-keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: .2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: .2;
  }
}
@keyframes blink {
  /**
   * At the start of the animation the dot
   * has an opacity of .2
   */
  0% {
    opacity: .2;
  }
  /**
   * At 20% the dot is fully visible and
   * then fades out slowly
   */
  20% {
    opacity: 1;
  }
  /**
   * Until it reaches an opacity of .2 and
   * the animation can start again
   */
  100% {
    opacity: .2;
  }
}
.blinkeyes {
  font-family: 'FuturaBold', Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-weight: normal;
  font-style: normal;
  text-align:justify;
  text-justify:inter-word;
  /* width:68px; */
  position:absolute;
  top:120px;
  left:395px;
  -webkit-animation-name: blink;
          animation-name: blink;
  -webkit-animation-duration: 1.4s;
          animation-duration: 1.4s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  /* font-size: 40px;
  color: red; */
  font-size: 2.1vh;
  z-index: 5;
}



.subtitle {
  margin: 0 0 2em 0;
}
.fancy {
  line-height: 0.5;
  text-align: center;
}
.fancy span {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
}
.fancy span:before,
.fancy span:after {
  content: "";
  position: absolute;
  height: 5px;
  border-bottom: 1px solid silver;
  border-top: 1px solid silver;
  top: 10px;
  width: 100%;
}
.fancy span:before {
  right: 100%;
  margin-right: 15px;
}
.fancy span:after {
  left: 100%;
  margin-left: 15px;
}

h4{
  font-family: 'OpenSansBold';
}


.parent {
  display: flex;
  height: 300px; /* Or whatever */
}

.child {
  width: 100px;  /* Or whatever */
  height: 100px; /* Or whatever */
  margin: auto;  /* Magic! */
}

.wrapper2{
  display: flex;  
  flex-flow: row wrap;
  /* font-weight: bold; */
  text-align: left;
}
.wrapper2 > *
{
  background-color: #EFEFEF;

  /* border: 1px solid red; */
}
.img{
  order:1;
  max-width: 50%;
}
.txt{
  order:2;
  max-width: 50%;
  padding: 20px;
}
.img1{
  order:4;
  max-width: 50%;
}
.txt1{
  order:3;
  max-width: 50%;
  padding: 20px;
}
.wrapper2 img{
  width: 100%;
}


@media screen and (max-width: 950px){
  
  .overlapping-div{
    width: 100%;
    position: relative; 
  }
  .container .calculator-container{
    border: 1px solid #3350B9;
    border: linear-gradient(to right, #00a950 10%, #3350B9 100%);
	padding: 1px; 
  }

  .img-section1{
    display: block;
  }
  .row{
    max-width: 100% !important;
  }
  .img-section1{
    display: none;
  }
  .carousel{
    display: none;
  }


  .img{
    order:1;
    max-width: 100%;
    width: 100%;
  }
  .txt{
    order:2;
    max-width: 100%;
  }
  .img1{
    order:3;
    max-width: 100%;
    width: 100%;
  }
  .txt1{
    order:4;
    max-width: 100%;
   
  }
}


.im2 {
  display: block;
  width: 100%;
}

.thumbnail {
  position: relative;
  display: inline-block;
}

.caption {
  position: absolute;
  top: 65%;
  left: 45%;
  -webkit-transform: translate( -50%, -50% );
          transform: translate( -50%, -50% );
  text-align: left;
  font-size: 1.3vw;
}

.testimonies {
  position: absolute;
  top: 65%;
  left: 80%;
  -webkit-transform: translate( -50%, -50% );
          transform: translate( -50%, -50% );
  text-align: left;
}

.caption >*{
  font-size: 1.3vw;
}

.testimonies >*{
  font-size: 1.1vw;
}

.Slider {
  position: absolute;
  top: 66%;
  left: 77%;
  -webkit-transform: translate( -50%, -50% );
          transform: translate( -50%, -50% );
  text-align: left;
  min-width: 37%;
}

.Slider >*{
  font-size: 1.1vw;
}
.carousel-row{
  text-align: center;
    /* margin-left: 75px !important;
    margin-right: 75px !important; */
}
.carousel-initialized button{
opacity: 0 !important;
}
.carousel-track {
  min-height:111px;
}
.carousel-row div>*
{
  font-size: 1.1vw !important;
}

.bg-customer{
  background:url('/images/Customer/background-image.jpg');
  background-repeat: no-repeat;
  background-size: cover;
background-size: 100% 100%;
}


@media screen and (max-width: 1000px){
  .container .calculator-container{
      max-width: 100% !important;
  }
}
.bgBar {
    background-color: #009789;
    padding: 10px 14px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    width: 100%;
}

.reset-box{
    padding-top: 48px;
    padding-bottom: 48px;
    max-width: 600px;
    margin: 0 auto;
}
.bgBar {
    background-color: #009789;
    padding: 10px 14px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    width: 100%;
}

.reset-box{
    padding-top: 48px;
    padding-bottom: 48px;
    max-width: 600px;
    margin: 0 auto;
}
/* 
html, body {
  font-family: "Open Sans", Helvetica, arial;
  font-size: 16px;
  line-height: 1.36;
  max-width: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;

  * {
    box-sizing: border-box;
  }
}

.container {
  margin: 20px auto;
  max-width: 400px;
  width: 100%;
} */
.btn {
  border-radius: 3px;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  /* padding: 10px 12px; */
  text-align: center; }
  .btn.-default {
    background-color: #fff;
    border: 1px solid #ddd;
    color: #4c5e65; }
    .btn.-default:hover {
      background-color: #423d3d; }
  .btn.-primary {
    background-color: #2989c6;
    border: 1px solid #2989c6; }
    .btn.-primary:hover {
      background-color: #257ab1; }

.progress {
  margin-bottom: 20px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: -webkit-gradient(linear, left top, right top, from(#fefb72), to(#f0bb31));
  background: linear-gradient(to right, #fefb72, #f0bb31); }

.progress-step {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 18px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  width: 100%; }

.docs-terms a {
  color: blue !important; }

.pull-right {
  float: right; }

.pull-left {
  float: left; }

.form-fields {
  list-style: none;
  margin: 0;
  padding: 0; }
  .form-fields > li {
    margin-bottom: 20px; }

.form-footer {
  border-top: 1px solid #ddd;
  margin-top: 10px;
  padding-top: 20px; }

.label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px; }

label {
  display: block;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 4px; }
  .checkbox label,
  .radio label {
    cursor: pointer;
    display: inline-block;
    font-weight: normal;
    padding: 1px 12px 1px 30px;
    position: relative; }
    .checkbox label input,
    .radio label input {
      left: 0;
      position: absolute;
      top: 0; }

input {
  font-size: 16px;
  padding: 8px;
  width: 100%; }

select {
  font-size: 16px;
  padding: 8px; }

.btn.-default:hover {
  background-color: #ddd; }

.error {
  /* border: 1px solid red; */
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.error-message {
  display: none; }

.error-message__visible {
  display: block;
  font-size: 0.75rem;
  margin: 0;
  color: #a94442; }

.has-error .form-control {
  border-color: #a94442;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.formErrors {
  color: #a94442; }

.terms {
  max-height: 300px;
  overflow: auto;
  border: 1px dashed blue;
  padding: 11px;
  text-align: justify; }

.Dropzone {
  height: 200px;
  width: 200px;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.Upload {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: flex-start;
  text-align: left;
  overflow: hidden;
}

.Content {
  display: flex;
  flex-direction: row;
  padding-top: 16px;
  box-sizing: border-box;
  width: 100%;
}

.FilesDoc {
  margin-left: 32px;
  align-items: flex-start;
  justify-items: flex-start;
  flex: 1 1;
  overflow-y: auto;
}

.Actions {
  display: flex;
  flex: 1 1;
  width: 100%;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 32px;
}

.Title {
  margin-bottom: 32px;
  color: #555;
}

.Filename {
  margin-bottom: 8px;
  font-size: 16px;
  color: #555;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  width: 100%;
}
.Row {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  justify-content: space-between;
  /* height: 50px; */
  padding: 8px;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  border: 1px dashed silver;
}

.CheckIcon {
  opacity: 0.5;
  margin-left: 32px;
}

.ProgressWrapper {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: center;
}

button {
  font-family: "Roboto medium", sans-serif;
  font-size: 14px;
  display: inline-block;
  height: 36px;
  min-width: 88px;
  padding: 6px 16px;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 0;
  border-radius: 2px;
  background: rgba(103, 58, 183, 1);
  color: #fff;
  outline: 0;
}

button:disabled {
  background: rgb(189, 189, 189);
  cursor: default;
}

.Dropzone{
  width: 100%;
  border: 0;
}
.ProgressBar {
  width: 100%;
  height: 8px;
  background-color: rgb(183, 155, 229);
  border-radius: 5px;
}

.Progress {
  background-color: rgba(103, 58, 183, 1);
  height: 100%;
  margin: 0;
  border-radius: 5px;
}

.demo-image-preview {
    position: relative;
    text-align: center;
  }
  
  .demo-image-preview > a > img {
    width: 100px;
  }
  .demo-image-preview > ModalImage {
    max-width: 100px;
  }  
  @media(max-width:768px){
    .demo-image-preview > img {
      width: 100%;
    }
    .demo-image-preview > ModalImage {
      width: 100%;
    }  
  }
  
  /* fullscreen enable by props */
  .demo-image-preview-fullscreen > img {
    width: 100vw;
    height:100vh;
  }
.camera{
    width: 100%;
}

.switch{
    width:50px
}
.camera h1{
    font-size: 10px;
}

.Upload {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
  }
  
  .Content {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
    max-height: 267px;
  }
  
  .Files {
    margin-left: 32px;
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1 1;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
  }
  
  
  .Title {
    margin-bottom: 32px;
    color: #555;
  }
  
  .react-html5-camera-photo>img, .react-html5-camera-photo>video {
    width: 334px;
}
.Main {
    text-align: center;
    background-color: rgb(206, 213, 223);
    /* min-height: 100vh; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
  }
  
  .Card {
    background-color: white;
    padding: 32px;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
      0 5px 15px 0 rgba(0, 0, 0, 0.08);
    box-sizing: border-box;
  }
  
.navbar {
    padding: 0px !important;
    background-color: #EFEFEF;
    text-align: left;
}

.navbar-toggler {
    border: 0px !important;
}

.hQVgAr{
    background-color: transparent !important;
}

.responsive-logo{
    width: 100% !important;
}

@media screen and (max-width: 991px){
    
.hQVgAr{
    position: relative !important;
    /* background: url(/images/Customer/background-image.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-size: 100% 100%;
    background-position: left; */
    width: 100% !important;
}
.Short-Width-Menu{
    width: 100%;
    background-color: #EFEFEF;
    position: relative;
}
.responsive-logo{
    width: auto !important;
    max-height: 42px;
}
.navbar{
    /* display: block; */
    /* position: absolute; */
    display: inline-table;
    top:0;
    right:0;
}
  
footer {
    position: fixed !important;
}
}



.calc-main{
    height: 100%; 
    min-height:200px; 
    padding:50px;
}
.Short-Width-Menu
{
    display: none;
}

.Full-Width-Menu
{
    display: block;
} 

@media screen and (max-width: 991px){
    .Short-Width-Menu
    {
        display: block;
        /* position: absolute; */
    }
    
    .Full-Width-Menu
    {
        display: none;
    } 
    
    .col-md-11 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
    }

    .calc-main{
        /* height: 100%; 
        min-height:200px;  */
        padding:10px;
        float: right;
        width: 100%;

    }
    .container{
        min-width: 100% !important;
    }
}

@media screen and (max-width: 601px){
    .container .calculator-container{
        float: right;
    }
}

.tableCell {
	width: 48%;
	display: table-cell;
	padding: 10px;
	text-align: left;
}
.tableRow {
	width: 800px;
	display: table-row;
}
p {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: bold;
	color: #333;
	margin-top: 5px;
	margin-right: 0px;
	margin-bottom: 8px;
	margin-left: 0px;
}
.table {
	display: table;
	/* width: 800px; */
	padding: 5px;
	margin: 0px;
}
.inputText {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	width: 100%;
}
.inputTextarea {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	height: 280px;
	width: 100%;
}
label {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
}

.backLink{
	color:blue !important;
}
.reset-box{
	padding: 0 !important;
	margin: 0 !important;
}


a{
    color: #8d8d8d !important;
  }
  
  /* a:hover{
    color: #FFF !important;
  }
  a:active{
    color: red !important;
  } */
  
.bgBar {
    background-color: #009789;
    padding: 10px 14px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    width: 100%;
}

.reset-box{
    padding-top: 48px;
    padding-bottom: 48px;
    max-width: 600px;
    margin: 0 auto;
}
.bgBar {
    background-color: #226ba4;
    padding: 10px 14px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    width: 100%;
}

.reset-box{
    padding-top: 48px;
    padding-bottom: 48px;
    max-width: 600px;
    margin: 0 auto;
}
.bgImg {
    background-image: url('/images/banner/4.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 64px;
    text-align: center;
    min-height: 400px;
    filter: blur(2px);
    -webkit-filter: blur(2px);
  /* transform: scale(1.1); */
}

.blur {
  background:url('/images/Homepage/header-1.jpg') no-repeat top;
  /* background-size:cover; */
  /* -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); */
  position:absolute;
  width:100%;
  min-height:80vh;
  padding-bottom: 64px;
  text-align: left;
  -webkit-animation: bg-anim 4s;
          animation: bg-anim 4s;
}


/* media styles */
@-webkit-keyframes bg-anim {
  from {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
  }
  to {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
}
@keyframes bg-anim {
  from {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
  }
  to {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
}

span {
  text-align: right;
  /* color:blue; */
  /* font-size:50px; */
  /* font-weight:bold; */
  position:relative;
  /* position:absolute; */
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(13px);
  filter: blur(0px);
}


html,
body {
   margin:0;
   padding:0;
   height:100%;
}
#container {
   min-height:100%;
   position:absolute;
   width: 100%;
}
#header {
   /* background:#1181B2; */
   /* background: linear-gradient(to right, rgba(17,129,178,1) 0%, rgba(0,177,255,1) 100%); */
   background: -webkit-gradient(linear, left top, right top, from(#EFEFEF), to(#EFEFEF));
   background: linear-gradient(to right, #EFEFEF 0%, #EFEFEF 100%);
   /* padding:10px; */
   color: #DDEDF4 !important;
}
#body {
   /* padding:10px; */
   /* background: linear-gradient(to right, #DDEDF4 0%, rgb(87,169,181) 100%); */ /* #DDEDF4; */
   padding-bottom:64px;   /* Height of the footer */
   min-height: 100vh;
}
#footer {
   position:absolute;
   bottom:0;
   width:100%;
   height:64px;   /* Height of the footer */
   /* background:#44449B; */
   /* color: antiquewhite;
   background: linear-gradient(to right, rgba(125, 38, 240, 0.877) 0%, rgba(68,68,155,1) 100%); */
}
/* 
.input-range__track {
  background: #3f51b5 !important;
}
.input-range__track--active {
  background: red !important;
}
.input-range__label-container{
  color: dodgerblue !important;
} */




@media screen and (max-width: 767px){
  .blur {
    background:url('/images/Homepage/header-1.jpg') no-repeat center;
    background-size:cover;
    /* -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px); */
    position:absolute;
    width:100%;
    min-height:80vh;
    padding-bottom: 64px;
    text-align: left;
  }

  .calculator-container{
    margin-left: 0px;
}

  }
  
  
  @media screen and (max-width: 540px){
    .blur {
      background:url('/images/Homepage/header-1.jpg') no-repeat center;
      background-size:cover;
      /* -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px); */
      position:absolute;
      width:100%;
      min-height:80vh;
      padding-bottom: 64px;
      text-align: left;
    }
   }
  
   
  @media screen and (max-width: 500px){
    
    .blur {
      background:url('/images/Homepage/header-1.jpg') no-repeat center;
      background-size:cover;
      /* -webkit-filter: blur(6px);
      -moz-filter: blur(6px);
      -o-filter: blur(6px);
      -ms-filter: blur(6px);
      filter: blur(6px); */
      position:absolute;
      width:100%;
      min-height:80vh;
      padding-bottom: 64px;
      text-align: left;
    }
    }
  
    
  @media screen and (max-width: 402px){
    .blur {
      background:url('/images/Homepage/header-1.jpg') no-repeat center;
      background-size:cover;
      /* -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -o-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px); */
      position:absolute;
      width:100%;
      min-height:80vh;
      padding-bottom: 64px;
      text-align: left;
    }

  }


.calculator-container{
    /* background: linear-gradient(to right, rgb(102,182,193) 0%, transparent 100%); */
    padding-top: 0px;
    /* margin-left: 0px; */
    padding-bottom: 0px;
}
.kibjRx{
    background-color: gray !important;
}
.jTcbqF {
    color:black !important;
}
.fqREXu {
    background-color: red !important;
}
.MuiIconButton-colorPrimary {
    color: red !important;
}
.kBBejf {
    
    background-color: #336688 !important;
}
.rdt_TableHeadRow .rdt_TableCol >input{
    display:none;

}
.rdt_ExpanderRow{
    background-color: white !important;
    color: black !important;
    padding: 10px;
}

.rdt_ExpanderRow a{
    color: lightcoral !important;
}
.container_expanded{
    padding: 10px;
    border: 1px solid gray;
}
.kibjRx{
    background-color: gray !important;
}
.jTcbqF {
    color:black !important;
}
.fqREXu {
    background-color: red !important;
}
.MuiIconButton-colorPrimary {
    color: red !important;
}
.kBBejf {
    
    background-color: #336688 !important;
}
.rdt_TableHeadRow .rdt_TableCol >input{
    display:none;

}
.rdt_ExpanderRow{
    background-color: white !important;
    color: black !important;
    padding: 10px;
}

.rdt_ExpanderRow a{
    color: lightcoral !important;
}
.container_expanded{
    padding: 10px;
    border: 1px solid gray;
}

.MuiPaper-root a{
    color:blue !important;
}
.MuiPaper-root a:hover{
    color:red !important;
}

@media (min-width: 992px)
{
    .col{
        padding: 0;
    } 
    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 100%;
        padding: 0;
    }
    .col-lg-12  {
    padding: 0;
}
  
}

.tableCell {
	width: 48%;
	display: table-cell;
	padding: 10px;
	text-align: left;
}
.tableRow {
	width: 800px;
	display: table-row;
}
p {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: bold;
	color: #333;
	margin-top: 5px;
	margin-right: 0px;
	margin-bottom: 8px;
	margin-left: 0px;
}
.table {
	display: table;
	/* width: 800px; */
	padding: 5px;
	margin: 0px;
}
.inputText {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	width: 100%;
}
.inputTextarea {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	height: 280px;
	width: 100%;
}
label {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
}

.backLink{
	color:blue !important;
}
.reset-box{
	padding: 0 !important;
	margin: 0 !important;
}
.customers {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 0.8rem;
  }
  
  .customers td, .customers th {
    border: 1px solid #ddd;
    padding: 8px;
  }
  
  .customers tr:nth-child(even){background-color: #f2f2f2;}
  
  .customers tr:hover {background-color: lightyellow;}
  
  .customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: silver;
    color: white;
    font-size: 0.8rem;
  }

  .customers thead {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: white;
    color: black;
    font-size: 0.8rem;
    padding: 0;
    margin: 0;
    border: 0;
  }
  
  .customers thead tr:hover {background-color: transparent;}
  
  .customers thead tr:nth-child(even){background-color: transparent;}

  .customers thead tr:nth-last-child td{
    padding: 0;
    margin: 0;
    border: 0;
  }

  .customers thead tr td:first-child{
    margin: 0;
    padding: 0;
    border: 0;
  }
body {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px; }

/* colors */
/* END DEFAULTS */
.table-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .table-wrapper .fixed-header-table {
    border-collapse: collapse;
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden; }
    .table-wrapper .fixed-header-table thead, .table-wrapper .fixed-header-table tbody, .table-wrapper .fixed-header-table tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; }
    .table-wrapper .fixed-header-table thead {
      height: 75px;
      position: absolute;
      top: 0;
      z-index: 10;
      background-color: #00BCD4;
      color: white;
      text-transform: uppercase;
      letter-spacing: 1px; }
    .table-wrapper .fixed-header-table tbody {
      display: block;
      height: 100%;
      overflow-y: auto;
      position: absolute;
      padding-top: 75px;
      box-sizing: border-box;
      color: #757575; }
    .table-wrapper .fixed-header-table tr th, .table-wrapper .fixed-header-table tr td {
      text-align: left;
      border-bottom: 1px solid #E0E0E0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .table-wrapper .fixed-header-table tr th {
      font-size: 1.2em;
      font-weight: normal;
      padding: 0 25px; }
    .table-wrapper .fixed-header-table tr td {
      padding: 20px 25px; }

/* table tr th:nth-child(1), table tr td:nth-child(1){
  display:none;
 } */

body {
  font-family: 'Open Sans', sans-serif;
  font-size: 13px; }

/* colors */
/* END DEFAULTS */
.table-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden; }
  .table-wrapper .fixed-header-table {
    border-collapse: collapse;
    position: relative;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden; }
    .table-wrapper .fixed-header-table thead, .table-wrapper .fixed-header-table tbody, .table-wrapper .fixed-header-table tbody tr {
      display: table;
      width: 100%;
      table-layout: fixed; }
    .table-wrapper .fixed-header-table thead {
      height: 75px;
      position: absolute;
      top: 0;
      z-index: 10;
      background-color: #00BCD4;
      color: white;
      text-transform: uppercase;
      letter-spacing: 1px; }
    .table-wrapper .fixed-header-table tbody {
      display: block;
      height: 100%;
      overflow-y: auto;
      position: absolute;
      padding-top: 75px;
      box-sizing: border-box;
      color: #757575; }
    .table-wrapper .fixed-header-table tr th, .table-wrapper .fixed-header-table tr td {
      text-align: left;
      border-bottom: 1px solid #E0E0E0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .table-wrapper .fixed-header-table tr th {
      font-size: 1.2em;
      font-weight: normal;
      padding: 0 25px; }
    .table-wrapper .fixed-header-table tr td {
      padding: 20px 25px; }

.tableCell {
	width: 48%;
	display: table-cell;
	padding: 10px;
	text-align: left;
}
.tableRow {
	width: 800px;
	display: table-row;
}
p {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	font-weight: bold;
	color: #333;
	margin-top: 5px;
	margin-right: 0px;
	margin-bottom: 8px;
	margin-left: 0px;
}
.table {
	display: table;
	/* width: 800px; */
	padding: 5px;
	margin: 0px;
}
.inputText {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	width: 100%;
}
.inputTextarea {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
	height: 280px;
	width: 100%;
}
label {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 12px;
}

.backLink{
	color:blue !important;
}
.bgImg {
    background-image: url('/images/banner/4.jpeg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 64px;
    text-align: center;
    min-height: 400px;
    filter: blur(2px);
    -webkit-filter: blur(2px);
  /* transform: scale(1.1); */
}

.blur {
  background:url('/images/Homepage/header-1.jpg') no-repeat top;
  /* background-size:cover; */
  /* -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px); */
  position:absolute;
  width:100%;
  min-height:80vh;
  padding-bottom: 64px;
  text-align: left;
  -webkit-animation: bg-anim 4s;
          animation: bg-anim 4s;
}


/* media styles */
@-webkit-keyframes bg-anim {
  from {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
  }
  to {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
}
@keyframes bg-anim {
  from {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(4px);
    -moz-filter: blur(4px);
    -o-filter: blur(4px);
    -ms-filter: blur(4px);
    filter: blur(4px);
  }
  to {
    -webkit-transform: scale(1.0);
            transform: scale(1.0);
    -webkit-filter: blur(0px);
    -moz-filter: blur(0px);
    -o-filter: blur(0px);
    -ms-filter: blur(0px);
    filter: blur(0px);
  }
}

span {
  text-align: right;
  /* color:blue; */
  /* font-size:50px; */
  /* font-weight:bold; */
  position:relative;
  /* position:absolute; */
  -webkit-filter: blur(0px);
  -moz-filter: blur(0px);
  -o-filter: blur(0px);
  -ms-filter: blur(13px);
  filter: blur(0px);
}


html,
body {
   margin:0;
   padding:0;
   height:100%;
}
#container {
   min-height:100%;
   position:absolute;
   width: 100%;
}
#header {
   /* background:#1181B2; */
   /* background: linear-gradient(to right, rgba(17,129,178,1) 0%, rgba(0,177,255,1) 100%); */
   background: -webkit-gradient(linear, left top, right top, from(#EFEFEF), to(#EFEFEF));
   background: linear-gradient(to right, #EFEFEF 0%, #EFEFEF 100%);
   /* padding:10px; */
   color: #DDEDF4 !important;
}
#body {
   /* padding:10px; */
   /* background: linear-gradient(to right, #DDEDF4 0%, rgb(87,169,181) 100%); */ /* #DDEDF4; */
   padding-bottom:64px;   /* Height of the footer */
   min-height: 100vh;
}
#footer {
   position:absolute;
   bottom:0;
   width:100%;
   height:64px;   /* Height of the footer */
   /* background:#44449B; */
   /* color: antiquewhite;
   background: linear-gradient(to right, rgba(125, 38, 240, 0.877) 0%, rgba(68,68,155,1) 100%); */
}
/* 
.input-range__track {
  background: #3f51b5 !important;
}
.input-range__track--active {
  background: red !important;
}
.input-range__label-container{
  color: dodgerblue !important;
} */




@media screen and (max-width: 767px){
  .blur {
    background:url('/images/Homepage/header-1.jpg') no-repeat center;
    background-size:cover;
    /* -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px); */
    position:absolute;
    width:100%;
    min-height:80vh;
    padding-bottom: 64px;
    text-align: left;
  }

  .calculator-container{
    margin-left: 0px;
    width: 100%;
}

  }
  
  
  @media screen and (max-width: 540px){
    .blur {
      background:url('/images/Homepage/header-1.jpg') no-repeat center;
      background-size:cover;
      /* -webkit-filter: blur(4px);
      -moz-filter: blur(4px);
      -o-filter: blur(4px);
      -ms-filter: blur(4px);
      filter: blur(4px); */
      position:absolute;
      width:100%;
      min-height:80vh;
      padding-bottom: 64px;
      text-align: left;
    }
   }
  
   
  @media screen and (max-width: 500px){
    
    .blur {
      background:url('/images/Homepage/header-1.jpg') no-repeat center;
      background-size:cover;
      /* -webkit-filter: blur(6px);
      -moz-filter: blur(6px);
      -o-filter: blur(6px);
      -ms-filter: blur(6px);
      filter: blur(6px); */
      position:absolute;
      width:100%;
      min-height:80vh;
      padding-bottom: 64px;
      text-align: left;
    }
    }
  
    
  @media screen and (max-width: 402px){
    .blur {
      background:url('/images/Homepage/header-1.jpg') no-repeat center;
      background-size:cover;
      /* -webkit-filter: blur(2px);
      -moz-filter: blur(2px);
      -o-filter: blur(2px);
      -ms-filter: blur(2px);
      filter: blur(2px); */
      position:absolute;
      width:100%;
      min-height:80vh;
      padding-bottom: 64px;
      text-align: left;
    }

  }


.calculator-container{
    /* background: linear-gradient(to right, rgb(102,182,193) 0%, transparent 100%); */
    padding-top: 0px;
    /* margin-left: 0px; */
    padding-bottom: 0px;
    /* max-width: 350px; */
    margin: 0;
}
.kibjRx{
    background-color: gray !important;
}
.jTcbqF {
    color:black !important;
}
.fqREXu {
    background-color: red !important;
}
.MuiIconButton-colorPrimary {
    color: red !important;
}
.kBBejf {
    
    background-color: #336688 !important;
}
.rdt_TableHeadRow .rdt_TableCol >input{
    display:none;

}
.rdt_ExpanderRow{
    background-color: white !important;
    color: black !important;
    padding: 10px;
}

.rdt_ExpanderRow a{
    color: lightcoral !important;
}
.container_expanded{
    padding: 10px;
    border: 1px solid gray;
}

.csvLink{
    color: white !important;
}
