.bgBar {
    background-color: #226ba4;
    padding: 10px 14px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    width: 100%;
}

.reset-box{
    padding-top: 48px;
    padding-bottom: 48px;
    max-width: 600px;
    margin: 0 auto;
}