
a{
    color: #8d8d8d !important;
  }
  
  /* a:hover{
    color: #FFF !important;
  }
  a:active{
    color: red !important;
  } */
  