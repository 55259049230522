.camera{
    width: 100%;
}

.switch{
    width:50px
}
.camera h1{
    font-size: 10px;
}

.Upload {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: flex-start;
    text-align: left;
    overflow: hidden;
  }
  
  .Content {
    display: flex;
    flex-direction: row;
    padding-top: 16px;
    box-sizing: border-box;
    width: 100%;
    max-height: 267px;
  }
  
  .Files {
    margin-left: 32px;
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: row;
  }
  
  
  .Title {
    margin-bottom: 32px;
    color: #555;
  }
  
  .react-html5-camera-photo>img, .react-html5-camera-photo>video {
    width: 334px;
}