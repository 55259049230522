body {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
  }
  
  
  /* colors */
  $red: #F44336;
  $pink: #E91E63;
  $purple: #9C27B0;
  $blue: #2962FF;
  $light-blue: #03A9F4;
  $cyan: #00BCD4;
  $teal: #009688;
  $green: #4CAF50;
  $yellow: #FFEB3B;
  $orange: #FF9800;
  $gray: #757575;
  $light-gray: #E0E0E0;
  $dark-gray: #212121;
  
  /* END DEFAULTS */
  $header-height: 75px;
  
  .table-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    
    .fixed-header-table {
      border-collapse: collapse;
      position: relative;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      
      thead, tbody, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
      
      thead {
        height: $header-height;
        position: absolute;
        top: 0;
        z-index: 10;
        background-color: $cyan;
        color: white;
        text-transform: uppercase;
        letter-spacing: 1px;
      }
      
      tbody {
        display: block;
        height: 100%;
        overflow-y: auto;
        position: absolute;
        padding-top: $header-height;
        box-sizing: border-box;
        color: $gray;
      }
      
      tr {
        th, td {
          text-align: left;
          border-bottom: 1px solid $light-gray;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        
        th {
          font-size: 1.2em;
          font-weight: normal;
          padding: 0 25px;
        }
        
        td {
          padding: 20px 25px;
        }
      }
    }
  }