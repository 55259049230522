.footerBG{
    background-image:url('/images/footer.jpg');
    background-repeat: no-repeat;
    /* background-position:center; */
	background-size:cover;
    width: 100%;
    height: 100%;
    min-height: 186px;
    position: absolute;
}

.social-icon{
    padding:5px;
    max-width: 40px;
}