

.calc-main{
    height: 100%; 
    min-height:200px; 
    padding:50px;
}
.Short-Width-Menu
{
    display: none;
}

.Full-Width-Menu
{
    display: block;
} 

@media screen and (max-width: 991px){
    .Short-Width-Menu
    {
        display: block;
        /* position: absolute; */
    }
    
    .Full-Width-Menu
    {
        display: none;
    } 
    
    .col-md-11 {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
    }

    .calc-main{
        /* height: 100%; 
        min-height:200px;  */
        padding:10px;
        float: right;
        width: 100%;

    }
    .container{
        min-width: 100% !important;
    }
}

@media screen and (max-width: 601px){
    .container .calculator-container{
        float: right;
    }
}
